import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
/** Page components */
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero from '../components/news/hero'
import Stories from '../components/news/stories'
import MainContent from '../components/news/main-content'
import PopularPosts from '../components/news/popular-posts'
import localeCheck from '../components/utils/locale-check'
const News = () => {
	const locale = 'en-AU'
	const defaultLocale = 'en-AU'
	const { page, stories, popular, heroBanner } = useStaticQuery(graphql`
		query newsPageQuery {
			page: allContentfulPage(filter: {contentful_id: {eq: "3QEDHFasXZdWHxmaO5TjvW"} , node_locale: { eq: "en-AU" }}) {
				edges {
					node {
						node_locale
						slug
						seoTitle
						seoKeywords
						seoDescription
						media {
							file {
								url
							}
						}
						body {
							json
						}
						updatedAt(locale: "en-AU", formatString: "llll")
					}
				}
			}
			stories: allContentfulPost(
				filter: {
					slug: {nin: [null]}, 
					node_locale: {eq: "en-AU"},
					contentful_id: {ne: "4C5lPaagfRxOolLxODiDen"},
					category: {ne: "Mineral Swim"}
				}, 
				sort: {
					fields: dateAndTime, order: DESC
				}
			) {
				edges {
					node {
						category
						slug
						seoTitle
						seoDescription
						node_locale
						dateAndTime(formatString: "DD MMMM, YYYY")
						image {
							file {
								url
							}
							description
						}
					}
				}
			}
			popular: allContentfulPost(
				sort: {fields: dateAndTime, order: DESC},
				filter: {
					slug: {nin: [null]}, 
					node_locale: {eq: "en-AU"},
					contentful_id: {ne: "4C5lPaagfRxOolLxODiDen"}, 
					category: {ne: "Mineral Swim"}
				}
			) {
				edges {
					node {
						node_locale
						seoTitle
						seoDescription
						slug
						category
						dateAndTime(formatString: "DD MMMM, YYYY")
						image {
							file {
								url
							}
						}
					}
				}
			}
			heroBanner: allContentfulPost(
				filter: {
					contentful_id: { eq: "6oiQ5hHMpEqiCWA18VChPm" },
					category: {ne: "Mineral Swim"},
					node_locale: { eq: "en-AU" }
				}
			) {
				edges {
					node {
						seoTitle
						seoDescription
						seoKeywords
						slug
						image {
							file {
								url
							}
						}
						node_locale
						category
					}
				}
			}
		}
	`)
	/** Filter Array by Locale */
	const sliceStories = stories.edges.slice(0, 5)
	const slicePopular = popular.edges.slice(0, 6)
	/** Destruct Filtered Array to Node */
	const {
		seoTitle,
		seoKeywords,
		seoDescription,
	} = page.edges[0].node
	const getNode = filteredArray => {
		return filteredArray.map((item, i) => {
			return item.node
		})
	}
	const [{ node: heroNode }] = heroBanner.edges
	const checkedLocale = localeCheck(locale, defaultLocale)
	const hero = {
		title: sliceStories[3].node.seoTitle,
		description: sliceStories[3].node.seoDescription,
		image: sliceStories[3].node?.image?.file?.url,
		category: sliceStories[3].node.category,
		cta: [
			{
				color: 'white',
				fill: false,
				name: 'Read more',
				link: `${heroNode.slug}`,
			},
		],
	}


	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
				lang={locale}
			/>
			<Hero {...hero} />
			<Stories stories={getNode(sliceStories)} checkedLocale={checkedLocale} />
			<MainContent
				stories={getNode(sliceStories)}
				locale={locale}
				checkedLocale={checkedLocale}
			/>
			<PopularPosts
				popularPosts={getNode(slicePopular)}
				checkedLocale={checkedLocale}
			/>
		</Layout>
	)
}
export default News
